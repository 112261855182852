.form-module {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  transition: transform 0.3s;
  cursor: pointer;
  width: 300px; /* Ancho fijo para mantener uniformidad */
  height: 300px; /* Alto fijo para mantener uniformidad */
  text-decoration: none;
  color: #1D7DBF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: 'Louis George Café', sans-serif;



}


.form-module:hover {
  transform: scale(1.05);
}

.form-module p {
  font-weight: bold;
}
