/* ModuleQuestions.css */
.question {
  /* Columnas */
  display: grid;

  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Louis George Café', sans-serif;
}

.question p {
  font-weight: bold;
}
h2 {
  color: #000;
  text-decoration: none;
  font-family: 'Exo', sans-serif;
}
label {
  margin-right: 10px;
}

.instruction {
  margin-top: 10px;
  color: #666;
}

.action {
  margin-top: 10px;
  color: #ff0000; /* Color de acción, puedes cambiarlo según tu preferencia */
  /* Esconde el div */
  display: none;
}

/* Ajusta estos estilos según tus preferencias */
.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  z-index: 1;
  font-size: 12px;
  max-width: 200px; /* Ajusta el ancho máximo según sea necesario */
  display: none; /* Se oculta inicialmente */
}

.question-text {
  display: flex;
  align-items: center;
}

.question-options {
  margin-top: 10px;  /* Ajusta según sea necesario */
}
.btn-evaluar {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #1DBF7D;
  color: #000;
  font-family: 'Louis George Café', sans-serif;
  cursor: pointer;
  /* Transicion con color a  1DBF7D*/
  transition: background-color 0.3s ease;

  /* Centra el boton completamente */
  display: block;
  margin: 0 auto;
  width: 50%;
  text-align: center;


}

.btn-evaluar:hover {
  background-color: #ccc;
  transition: background-color 0.3s ease;
}


.alert_query{
  margin-top: 10px;
  padding: 10px;
  margin-left: 2px;
  border-radius: 8px;
  background-color: #F5F6F5;
  color: #d11f1f;
  font-family: 'Louis George Café', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Centra el texto */
  text-align: center;

}
/* Definir las clases de desvanecimiento */
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

/* Clase para ocultar el elemento */
.hidden {
  display: none;
}

.info-icon {
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0.5;
}
