 .footer {
    background-color: #1a1a1a;
    color: #fff;
    border-top: 1px solid #ccc;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
  .social-icons {
    margin-top: 15px;
  }
  
  .social-icon {
    width: 30px; /* Ajusta el tamaño según tus necesidades */
    margin: 0 10px;
  }
  