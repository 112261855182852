
.about-us-section {
    background-color: #f2f2f2;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column; /* Añadido para que los elementos estén en columna */
    margin-top: 20px; /* Ajusta según sea necesario */
  }
  
  .about-us-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-description {
    font-size: 1.2em;
    color: #666;
  }
  