/* Estilos para la landing page */
.landing-page {
  display: flex;
  flex-direction: column; /* Añadido para que los elementos estén en columna */
  align-items: center;
  justify-content: left;
  width: 40%;
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Hace que el fondo tenga espacio */

}
.landing-content {
  text-align: center;
  margin-top: 50px;
  color: #F5F6F5; /* Ajusta según sea necesario para que el texto sea legible en la imagen de fondo */
}

.landing-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.landing-content p {
  font-size: 1.5em;
  color: #F5F6F5; /* Ajusta según sea necesario para que el texto sea legible en la imagen de fondo */
  margin-bottom: 30px;
}

.btn {
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
}
.landing-image-div {
  width: 100%;
  height: 100%; /* Ajusta la altura según tus necesidades */
  overflow: hidden;
}

.landing-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen se ajuste al contenedor sin distorsionar la relación de aspecto */
}
.cta-box {
  overflow: hidden;
  
  position: absolute;
  /* calcula a partir de landing-image el top */
  top: 16vh;
  right: 20vh;
  width: 600px; /* Ajusta el ancho según tus necesidades */
  height: 60%;
  background-color: #1DBF7D; /* Fondo semi-transparente blanco */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  
}

.cta-content {
  padding: 20px;
  text-align: left;
  color: #F5F6F5; /* Ajusta según sea necesario para que el texto sea legible en la imagen de fondo */
  /* Justifica */
  text-align: justify;
  text-justify: inter-word;
  font-family: 'Louis George Café', sans-serif;
  
}

.read-more-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #282B28; 
  color: #F5F6F5;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 15px;
  /* Centra boton */
  margin-left: 40%;
}
.read-more-btn:hover {
  background-color: #F5F6F5;
  color: #282B28;
  transition: background-color 0.5s ease-in-out;
}

@media (max-width: 1267px) {
  .landing-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .landing-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-image-div {
    width: 60%;
    height: 40%;
    overflow: hidden;
  }


  .landing-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* cta va bajo la imagen */
  .cta-box {
    width: 60%;
    height: 70%;
    top: 80vh;
    right: 19vh;
    background-color: #1DBF7D(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cta-content {
    
    max-width: 300px;
    padding: 10px;
    /* Justifica el texto */
    text-align: justify;
  }
  .read-more-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #282B28; 
    color: #F5F6F5;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 15px;
    /* Centra boton */
    margin-left: 31%;
  }
  .read-more-btn:hover {
    background-color: #F5F6F5;
    color: #282B28;
    transition: background-color 0.5s ease-in-out;
  }
}


/* Responsivo para celulares */
@media (max-width: 767px) {
  .landing-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .landing-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-image-div {
    width: 100%;
    height: 40%;
    overflow: hidden;
  }


  .landing-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* cta va bajo la imagen */
  .cta-box {
    width: 100%;
    height: 40%;
    top: 65vh;
    right: 0vh;
    background-color: #1DBF7D(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .cta-content {
    max-width: 450px;
    padding: 1px;
    font-size: small;
    /* Justifica el texto */
  }
  .read-more-btn {
    padding: 5px 10px;
    background-color: #282B28; 
    color: #F5F6F5;
    text-decoration: none;
    border-radius: 1px;
    margin-bottom: 10%;
    /* Centra boton */
    margin-left: 40%;
  }
  .read-more-btn:hover {
    background-color: #F5F6F5;
    color: #282B28;
    transition: background-color 0.5s ease-in-out;
  }
}