.forms-page {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  font-family: 'Louis George Café', sans-serif;
}

.form-module {
  padding: 20px;
  border: 1px solid #ccc;
}

h2 {
  color: #000;
  text-decoration: none;
}

a {
  text-decoration: none;
}
