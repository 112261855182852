nav {
  display: flex;
    justify-content: space-around;
  align-items: center;
  background-color: #282B28;
  backdrop-filter: blur(10px);
  position: sticky;
  font-family: 'Inter', sans-serif;
  top: 0;
  z-index: 100;
  padding: 0.5rem 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

}

nav .title {
  img{
    margin: 0;
    padding: 0;
    width: 50px;
    height: 55px;
  }
}

nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

nav ul li {
 list-style: none; 

}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #ffffff;
  padding: 8px;
  margin: 0 0.9rem;
}

nav ul li a:hover {
  background-color: #3d403d;
  border-radius: 16px;

}
.login{
  font-weight: bold;
  border-radius: 8px;
  padding: 8px;
  background-color: #3d403d;
  text-decoration: none;
  color: #ffffff;
  transition: all ease-in-out 0.1s;
  &:hover{
    background-color: #1DBF7D;
  }

}
nav .menu {
  display: none;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #1DBF7D;
  border-radius: 0.2rem;
}
.logo{
  width: 50px;
  height: 40px;
  margin-left: 10px;
}
.logoText {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1DBF7D;
  /* selecciona nunito como font */
  font-family: 'Exo', sans-serif;
}

.logoText2 {
  font-weight: bold;
  font-size: 1.5rem;
  color: #282B28;
  /* selecciona nunito como font */
  font-family: 'Exo', sans-serif;
}

.register{
  font-weight: bold;
  font-size: 1.5rem;
  /* selecciona nunito como font */
  font-family: 'Exo', sans-serif;
  border-radius: 0.5rem;
}

@media (max-width: 480px) {
  nav .menu {
      display: flex;
  }

  nav {
      flex-direction: column;
      align-items: flex-start;
  }

  nav ul {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem;
  }

  nav ul.open {
      display: flex;
  }

  nav ul li {
      width: 100%;
      text-align: center;
  }

  nav ul li a {
      margin: 0.2rem 0.5rem;
  }
}